<template>
  <div class="favouriteMatches-section">
    <a-card>
      <template #title>
        <div class="mt-1">
          <div class="d-flex font-size-20">
            <span v-if="showLabel && sectionTitle" class="section-title">
              {{ sectionTitle }}
            </span>
          </div>
        </div>
      </template>
      <template #extra>
        <div class="d-flex align-items-center section-extra font-size-20 text-right">
          <div class="mt-0 d-flex align-items-center">
            <span>
              <a-button  type="primary" icon="plus" disabled>
                Add
              </a-button>
            </span>
            <div v-if="isEditAvailable" class="mr-auto ml-3" style="margin-top: 0.1rem">
              <a-tooltip title="Edit section" placement="bottom">
                <a-icon @click="editSection" type="edit" class="main-tabs__sections__icons text-primary" />
              </a-tooltip>
            </div>
          </div>
          <div class="mr-auto ml-3">
            <span class="">
              <a-tooltip v-if="index < maxIndex - 1 && isEditAvailable" title="Move down" placement="bottom">
                <a-icon @click="moveDown" type="down" class="movable move-down text-primary" />
              </a-tooltip>
              <a-icon v-else type="down" class="movable text-gray-5" />
            </span>
            <span class="ml-3">
              <a-tooltip v-if="index > 0 && isEditAvailable" title="Move up" placement="bottom">
                <a-icon @click="moveUp" type="up" class="movable move-up text-primary" />
              </a-tooltip>
              <a-icon v-else type="up" class="movable text-gray-5" />
            </span>
          </div>
          <a href="javascript:" class="text-primary ml-3" @click.stop="changeStatusSection">
            <a-switch v-model="isActiveSectionStatus" class="ml-3" :disabled="!isEditAvailable"/>
          </a>
          <div class="ml-3 dropDown-icon disabled  text-gray-5">
            <DropDownIcon disabled />
          </div>
        </div>
      </template>
      <template #cover>
        <div class="favourite-content">
          <span>В секции отображаются матчи, которые пользователь самостоятельно добавляет в раздел избранное.</span>
        </div>
      </template>
    </a-card>
  </div>
</template>
<script>
import DropDownIcon from '@/components/custom/icons/dropDownIcon.vue'

export default {
  name: 'favouriteMatchesSection',
  props: ['index', 'maxIndex', 'selectedLang', 'showLabel', 'isActive', 'localizations', 'sectionId', 'isEditAvailable'],
  components: {
    DropDownIcon,
  },
  data: () => ({
    isActiveSectionStatus: false,
    windowWidth: window.innerWidth,
  }),
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
  },
  computed: {
    sectionTitle() {
      const selectedLang = this.selectedLang === 'ru' ? 0 : 2
      const localization = this.localizations.find(section => section.lang_id === selectedLang)
      return localization ? localization.value : null
    },
  },
  created() {
    this.isActiveSectionStatus = this.isActive
  },
  methods: {
    editSection() {
      this.$emit('editSection', this.sectionId)
    },
    moveDown() {
      this.$emit('moveDownSection', this.sectionId, 1)
    },
    moveUp() {
      this.$emit('moveUpSection', this.sectionId, -1)
    },
    changeStatusSection() {
      this.$emit('changeStatusSection', this.sectionId, this.isActiveSectionStatus)
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
