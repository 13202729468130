<template>
  <a-form-model ref="linkEditForm" :model="form" :rules="rules">
    <div class="width-100p text-center">
      <a-upload name="avatar" list-type="picture-card" action="#"
        :class="imageRequirements.proportions.ratio > 1 ? 'link-cover-uploader-2' : 'link-cover-uploader-1'"
        :show-upload-list="false" :before-upload="beforeUpload" @change="imgChange">
        <img v-if="form.img_path" :src="form.img_path" alt="link image" class="img_link_cover" />
        <div v-else>
          <a-icon :type="imgLoading ? 'loading' : 'inbox'" class="text-gray-6 font-size-32" />
          <div class="ant-upload-text text-gray-6 font-weight-lighter font-size-14">
            Image size
            <span class="font-size-12 d-block">
              {{ imageRequirements.min_width }} x {{ imageRequirements.min_height }} px<br />
              ( .jpg or .png )
            </span>
          </div>
        </div>
      </a-upload>
    </div>
    <div class="mb-3">
      <a-row>
        <a-col :span="24">
          <div class="d-flex justify-content-between name-select-container">
            <span class="mb-1 font-weight-bold">Name :</span>
            <a-select v-model="selectedLangId" size="small" :showArrow="false" class="lang-select">
              <a-select-option v-for="lang in linkLangs" :key="lang.value">
                {{ lang.label }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <a-form-model-item>
              <a-input class="mr-3" v-model="currentLinkName" size="large" :disabled="submitLoading" />
            </a-form-model-item>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-form-model-item ref="link" label="Link" prop="link">
      <a-input-group compact class="d-flex">
        <a-select v-model="form.link_type" size="large" :disabled="submitLoading" class="mr-1">
          <a-select-option value="webview">
            WebView
          </a-select-option>
          <a-select-option value="deeplink">
            Deeplink
          </a-select-option>
        </a-select>
        <a-input v-model="form.link" size="large" :disabled="submitLoading" class="ml-auto" />
      </a-input-group>
    </a-form-model-item>
    <a-form-model-item ref="is_active" prop="is_active">
      <a-switch v-model="form.is_active" :disabled="submitLoading"  />
      <span class="ml-2">Published</span>
    </a-form-model-item>
    <hr>
    <a-row>
      <a-col class="text-left" :span="6">
        <span v-if="linkObj">
          <a-popconfirm title="Sure to delete?" @confirm="deleteRecord">
            <a href="javascript:;">
              <a-button type="danger" :loading="deleteLoading">Delete</a-button>
            </a>
          </a-popconfirm>
        </span>
      </a-col>
      <a-col class="text-right" :span="18">
        <a-button @click="closeModal">
          Cancel
        </a-button>
        <a-button v-if="linkObj" :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
          Save
        </a-button>
        <a-button v-else :loading="submitLoading" type="primary" @click="onSubmit" class="ml-2">
          Create
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>
<script>
import TopService from '@/services/api/apiTopService'
import ViewscreenService from '@/services/api/apiViewscreenService'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'linkModal',
  props: ['linkObj', 'shortcutType', 'sectionId', 'langs'],
  components: {},
  data() {
    return {
      linkLangs: [
        { label: 'Russian', value: '0', shortcut: 'ru' },
        { label: 'Kazakh', value: '1', shortcut: 'kk' },
        { label: 'English', value: '2', shortcut: 'en' },
        { label: 'Chinese', value: '3', shortcut: 'cn' },
        { label: "O'zbek", value: '22', shortcut: 'uz' },
      ],
      selectedLangId: '0',
      submitLoading: false,
      deleteLoading: false,
      imgLoading: false,
      imageUrl: this.linkObj ? this.linkObj.img_path : '',
      updateCover: false,
      form: {
        link: this.linkObj ? this.linkObj.link : '',
        link_type: this.linkObj ? this.linkObj.link_type : 'webview',
        weight: this.linkObj ? this.linkObj.weight : 0,
        is_active: this.linkObj ? this.linkObj.is_active : false,
        img_path: this.linkObj ? this.linkObj.img_path : '',
        imgFile: undefined,
        linkNames: {
          ru: '',
          kk: '',
          en: '',
          cn: '',
          uz: '',
        },
      },
      rules: {
        link: [{
          required: true,
          message: 'Shortcut link is required',
          trigger: 'change',
        }],
      },
    }
  },
  watch: {
    linkIsFilled(val) {
      if (val) {
        this.rules.link = [{ required: true, validator: this.validateLink, trigger: 'change' }]
      } else {
        this.$refs.linkEditForm.clearValidate()
        this.rules.link = [{ required: false, validator: this.validateLink, trigger: 'change' }]
      }
    },
  },
  computed: {
    selectedLang() {
      return this.linkLangs.find(lang => lang.value === this.selectedLangId)
    },
    currentLinkName: {
      get() {
        return this.form.linkNames[this.selectedLang.shortcut]
      },
      set(newValue) {
        this.$set(this.form.linkNames, this.selectedLang.shortcut, newValue)
      },
    },
    linkIsFilled() {
      return this.form.link.length
    },
    sectionX() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return 1
          case 'shortcut_medium': return 1
          case 'shortcut_large': return 28
          default: return 1
        }
      })()
    },
    sectionY() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return 1
          case 'shortcut_medium': return 1
          case 'shortcut_large': return 10
          default: return 1
        }
      })()
    },
    minX() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return 60
          case 'shortcut_medium': return 100
          case 'shortcut_large': return 280
          default: return 100
        }
      })()
    },
    minY() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return 60
          case 'shortcut_medium': return 100
          case 'shortcut_large': return 100
          default: return 100
        }
      })()
    },
    imageRequirements() {
      const ratio = Math.round(10 * (this.sectionX / this.sectionY)) / 10
      return {
        proportions: {
          x: this.sectionX,
          y: this.sectionY,
          ratio: ratio,
        },
        min_width: this.minX,
        min_height: this.minY,
      }
    },
  },
  methods: {
    changePublish() {
      this.form.is_active = !this.form.is_active
    },
    validateLink(rule, value, callback) {
      if (this.form.link_type === 'deeplink') {
        if (this.form.link_type.length) {
          callback()
        } else {
          callback(new Error('Link is required'))
        }
        return
      }
      if (value.length && rule.required) {
        /* eslint-disable no-useless-escape */
        const RegExpLnk = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/
        if (RegExpLnk.test(value)) {
        } else {
          callback(new Error('Wrong link'))
        }
      } else {
        callback(new Error('Link is required'))
      }
      callback()
    },
    imgChange(info) {
      if (info.file.status === 'uploading') {
        this.imgLoading = true
        return
      }
      this.form.imgFile = info.file.originFileObj
      getBase64(info.file.originFileObj, imageUrl => {
        this.imageUrl = imageUrl
        this.form.img_path = imageUrl
        this.imgLoading = false
      })
      this.updateCover = true
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$notification.error({
          message: 'You can only upload JPG or PNG file!',
        })
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$notification.error({
          message: 'Image must smaller than 5MB!',
        })
        return false
      }
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = (err) => {
          const inProportions = Math.round(10 * (img.width / img.height)) / 10 === this.imageRequirements.proportions.ratio
          if (!inProportions) {
            this.$notification.error({
              message: `Image must be in correct proportions - ${this.imageRequirements.proportions.x} : ${this.imageRequirements.proportions.y}`,
            })
            reject(err)
          } else {
            resolve()
          }
        }
      })
    },
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitCreateModal(createdRecord) {
      this.$emit('createLink', createdRecord)
    },
    submitEditModal(updatedRecord) {
      this.$emit('updateLink', updatedRecord)
    },
    submitDeleteModal(deletedId) {
      this.$emit('deleteLink', deletedId)
    },
    onSubmit() {
      this.$refs.linkEditForm.validate(valid => {
        if (valid) {
          if (this.linkObj) {
            this.updateViewscreenRecord()
          } else {
            this.createViewscreenRecord()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getLinkPayload() {
      const payload = {
        link: this.form.link,
        link_type: this.form.link_type,
        weight: this.form.weight,
        is_active: this.form.is_active,
        img_path: this.form.img_path,
        imgFile: this.form.imgFile,
      }
      const filteredLocalizations = Object.entries(this.form.linkNames)
        .filter(([lang, name]) => name !== '')
        .map(([lang, name]) => {
          const langInfo = this.linkLangs.find(l => l.shortcut === lang)
          console.log(lang)
          return {
            lang_id: langInfo ? langInfo.value : null,
            title: name,
          }
        })
        .filter(item => item.lang_id !== null)

      if (filteredLocalizations.length) {
        payload.localizations = filteredLocalizations
      }
      return payload
    },
    async createViewscreenRecord() {
      if (!this.form.imgFile) {
        this.$notification.error({
          message: 'Upload image to create new link',
        })
        return false
      }
      this.submitLoading = true
      return TopService.createSectionlessRecord(this.getLinkPayload()).then((response) => {
        this.submitLoading = false
        const created = response.data.data
        this.$notification.success({
          message: 'New link created',
          description: '',
        })
        this.putCover(created)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating link',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    async updateViewscreenRecord() {
      this.submitLoading = true
      return TopService.updateSectionlessRecord(this.linkObj.id, this.getLinkPayload()).then((response) => {
        this.submitLoading = false
        const updated = response.data.data
        this.$notification.success({
          message: 'Link updated',
          description: '',
        })
        if (this.updateCover) { this.putCover(updated, true) } else { this.submitEditModal(updated) }
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
        this.$notification.error({
          message: 'Error while updating link',
          description: error.message,
        })
      })
    },
    async deleteRecord() {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteViewscreenSectionItem(this.sectionId, this.linkObj.id)
        if (response.data.success === true) {
          this.submitDeleteModal(this.linkObj.id)
          this.$notification.success({
            message: 'Link successfully deleted',
          })
          this.matchModal = false
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting link',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
    putCover(record, onEdit = false) {
      return TopService.recordPutSectionlessCover(record.id, this.form.imgFile).then((response) => {
        const uploaded = response.data.data
        this.$notification.success({
          message: 'Link cover uploaded',
          description: '',
        })
        this.submitLoading = false
        if (onEdit) { this.submitEditModal(uploaded) } else { this.submitCreateModal(uploaded) }
      }).catch(error => {
        this.$notification.error({
          message: 'Error while uploading link cover',
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    fillLocalizations () {
      this.linkObj.localizations.forEach(lang => {
        const langInfo = this.linkLangs.find(l => {
          return Number(l.value) === Number(lang.lang_id)
        })
        console.log(langInfo, lang)
        if (langInfo) {
          this.form.linkNames[langInfo.shortcut] = lang.title
        }
      })
    },
  },
  mounted() {
    if (this.linkObj) {
      this.fillLocalizations()
    }
    if (this?.linkObj?.link?.length) {
      this.rules.link = [{ required: true, validator: this.validateLink, trigger: 'change' }]
    }
  },
}
</script>
<style scoped lang="scss">
.ant-form-item-label {
  padding: 0px !important;
}

.link-image {
  margin-bottom: 20px;
  display: inline-block;
}

</style>
