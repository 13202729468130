<template>
  <div class="topMatches-section">
    <a-card class="top-matches">
      <template #title>
        <div class="mt-1">
          <div class="d-flex font-size-20">
            <span v-if="sectionTitle" class="section-title" :class="{ 'hidden-title': !showLabel }" >
              {{ sectionTitle }}
            </span>
          </div>
        </div>
      </template>
      <template #extra>
        <div class="d-flex align-items-center section-extra font-size-20 text-right">
          <div class="mt-0 d-flex align-items-center">
            <span>
              <a-button :disabled="!isEditAvailable" type="primary" icon="plus" @click="addTopMatch">
                Add
              </a-button>
            </span>
            <div v-if="isEditAvailable" class="mr-auto ml-3" style="margin-top: 0.1rem">
              <a-tooltip title="Edit section" placement="bottom">
                <a-icon @click="editSection" type="edit" class="main-tabs__sections__icons text-primary" />
              </a-tooltip>
            </div>
          </div>
          <div class="mr-auto ml-3">
            <span class="">
              <a-tooltip v-if="index < maxIndex - 1 && isEditAvailable" title="Move down" placement="bottom">
                <a-icon @click="moveDown" type="down" class="movable move-down text-primary" />
              </a-tooltip>
              <a-icon v-else type="down" class="movable text-gray-5" />
            </span>
            <span class="ml-3">
              <a-tooltip v-if="index > 0 && isEditAvailable" title="Move up" placement="bottom">
                <a-icon @click="moveUp" type="up" class="movable move-up text-primary" />
              </a-tooltip>
              <a-icon v-else type="up" class="movable text-gray-5" />
            </span>
          </div>
          <a href="javascript:" class="text-primary ml-3" @click.stop="changeStatusSection">
            <a-switch v-model="isActiveSectionStatus" class="ml-3" :disabled="!isEditAvailable" />
          </a>
        </div>
      </template>
      <template #cover>
        <transition-group v-if="groupedMatches" tag="div" class="grid" type="transition" name="flip-grid">
          <div v-for="(matchGroup, index) in groupedMatches" :key="index">
            <TopMatchTable :matchGroupData="matchGroup" :limits="matchLimits" :sectionId="sectionId" :isEditAvailable="isEditAvailable" @editTopMatch="openEditMatch" @deleteTopMatch="handleDeleteMatch" @toggleIsPublished="emitTogglePublish"/>
          </div>
        </transition-group>
      </template>
    </a-card>
    <a-modal v-model="matchModal"
      :title="matchToEdit && matchToEdit.id ? `Edit` : 'Add match'" :footer="null"
      class="match-modal" width="600px" :destroyOnClose="true">
      <MatchModal @createMatch="handleCreateMatch" @updateTopSort="handleUpdateMatch" @deleteSectionMatch="handleDeleteMatch"
        @closeModalFunction="matchModal = false" :match-obj="matchToEdit"
        :edit="matchToEdit && matchToEdit.id ? true : false" :isViewscreenSection="true" ref="matchModal" />
    </a-modal>

  </div>
</template>
<script>
import MatchModal from '@/components/custom/modals/matchModal.vue'
import TopMatchTable from '@/views/apps/viewscreens/components/topMatchTable.vue'
import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'topMatchesSection',
  props: ['topMatchesData', 'index', 'maxIndex', 'selectedLang', 'langs', 'showLabel', 'isActive', 'localizations', 'sectionId', 'limits', 'viewscreenId', 'isEditAvailable'],
  components: {
    MatchModal, TopMatchTable,
  },
  data: () => ({
    loading: false,
    isActiveSectionStatus: false,
    topMatches: null,
    publishLoading: false,
    matchModal: false,
    matchToEdit: undefined,
    matchLimits: null,
  }),
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
  },
  mounted() {
    this.topMatches = this.topMatchesData
    this.matchLimits = this.limits
    this.isActiveSectionStatus = this.isActive
  },
  computed: {
    selectedLangId() {
      return this.langs.find(lang => this.selectedLang === lang.shortcut).value
    },
    sectionTitle() {
      const localization = this.localizations.find(section => Number(section.lang_id) === Number(this.selectedLangId))
      return localization ? localization.value : null
    },
    groupedMatches() {
      const groupBy = (array, key1, key2) => {
        return array.reduce((result = [], currentValue = []) => {
          (result[currentValue[key1] + ';' + currentValue[key2]] = result[currentValue[key1] + ';' + currentValue[key2]] || []).push(currentValue)
          return result
        }, {})
      }
      if (this.topMatches) {
        const grouped = groupBy(this.topMatches, 'sport_name', 'champ_name')
        return Object.keys(grouped).map(key => {
          const sportAndChamp = key.split(';')
          return { sportName: sportAndChamp[0], champName: sportAndChamp[1], matches: grouped[key] }
        })
      }
      return null
    },
  },
  methods: {
    addTopMatch() {
      this.matchToEdit = null
      this.openMatchModal()
    },
    editSection() {
      this.$emit('editSection', this.sectionId)
    },
    moveDown() {
      this.$emit('moveDownSection', this.sectionId, 1)
    },
    moveUp() {
      this.$emit('moveUpSection', this.sectionId, -1)
    },
    changeStatusSection() {
      this.$emit('changeStatusSection', this.sectionId, this.isActiveSectionStatus)
    },
    openEditMatch(matchId) {
      const editMatch = this.topMatches.find(match => match.id === matchId)
      this.matchToEdit = JSON.parse(JSON.stringify(editMatch))
      this.openMatchModal()
    },
    openMatchModal() {
      this.matchModal = true
    },
    async handleCreateMatch(createdMatch) {
      this.matchModal = false
      await this.getTopMatchSection()
      await this.addMatchSection(createdMatch.id)
      this.topMatches.unshift(createdMatch)
      this.$emit('toggleIsPublished')
    },
    async handleUpdateMatch(newWeight, matchId) {
      try {
        const sort = { sort: [{ id: matchId, sort: newWeight }] }
        await ViewscreenService.sortSectionItems(this.sectionId, sort)
        this.$notification.success({
          message: 'Sucessfully changed weight',
        })
        const index = this.topMatches.findIndex(match => match.id === matchId)
        this.topMatches[index].weight = newWeight
        this.topMatches[index].updated_at = newWeight
        this.topMatches.sort((a, b) => b.weight - a.weight)
        this.matchModal = false
        this.$emit('toggleIsPublished')
      } catch (error) {
        console.log(error)
      }
    },
    async addMatchSection (matchId) {
      this.loading = true
      try {
        const response = await ViewscreenService.addViewscreenSectionItem(this.sectionId, matchId)
        if (response.success) {
          this.$notification.success({
            message: 'Top match has been successfully added to the viewscreen section.',
          })
        }
        this.$emit('toggleIsPublished')
      } catch (e) {
        this.$notification.error({
          message: e.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async handleDeleteMatch(matchId) {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteViewscreenSectionItem(this.sectionId, matchId)
        if (response.data.success === true) {
          const deletedIndex = this.topMatches.findIndex(match => match.id === matchId)
          if (deletedIndex !== -1) {
            this.topMatches.splice(deletedIndex, 1)
          }
          this.$notification.success({
            message: 'Match has been successfully deleted from section',
          })
          this.matchModal = false
          this.$emit('toggleIsPublished')
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting match',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
    async getTopMatchSection() {
      this.tabsLoading = true
      try {
        const response = await ViewscreenService.getViewscreenSection(this.viewscreenId, this.sectionId)
        if (response.data.data) {
          console.log(response.data.data)
          this.matchLimits = response.data.data.limits
        }
      } finally {
        this.tabsLoading = false
      }
    },
    emitTogglePublish() {
      this.$emit('toggleIsPublished')
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
