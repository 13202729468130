<template>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50195 17.7144H3.50214C2.97714 17.7144 3.00214 17.2573 3.00214 16.5716C3.00214 15.8859 2.97714 15.4287 3.50214 15.4287H9.50195C10.027 15.4287 10.0021 15.8859 10.0021 16.5716C10.0021 17.2573 10.027 17.7144 9.50195 17.7144Z"
        :fill="fillColor"
      />
      <path
        d="M12.5019 11.9996H3.50195C2.9565 11.9996 3.0019 11.5424 3.0019 10.8567C3.0019 10.171 2.9565 9.71387 3.50195 9.71387H12.5019C13.0474 9.71387 13.0019 10.171 13.0019 10.8567C13.0019 11.5424 13.0474 11.9996 12.5019 11.9996Z"
        :fill="fillColor"
      />
      <path
        d="M17.212 18.857C16.6436 18.857 16.2646 18.4456 16.2646 17.8284V9.59986C16.2646 8.98272 16.6436 8.57129 17.212 8.57129C17.7804 8.57129 18.1594 8.98272 18.1594 9.59986V17.8284C18.1594 18.4456 17.7804 18.857 17.212 18.857Z"
        :fill="fillColor"
      />
      <path
        d="M17.2123 20.0004C16.9281 20.0004 16.7386 19.8861 16.5492 19.6576L13.7071 16.229C13.3281 15.7718 13.3281 15.0861 13.7071 14.629C14.086 14.1718 14.6544 14.1718 15.0334 14.629L17.2123 17.2576L19.3913 14.629C19.7702 14.1718 20.3386 14.1718 20.7176 14.629C21.0965 15.0861 21.0965 15.7718 20.7176 16.229L17.8755 19.6576C17.686 19.8861 17.4965 20.0004 17.2123 20.0004Z"
        :fill="fillColor"
      />
      <path
        d="M15.5017 6.28571H3.50146C2.94432 6.28571 3.00166 5.82857 3.00166 5.14286C3.00166 4.45714 2.94432 4 3.50146 4H15.5017C16.0588 4 16.0017 4.45714 16.0017 5.14286C16.0017 5.82857 16.0588 6.28571 15.5017 6.28571Z"
        :fill="fillColor"
      />
    </svg>
  </template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fillColor() {
      return this.disabled ? '#a1a1c2' : '#1890FF'
    },
  },
}
</script>
